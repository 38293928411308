*{
  margin: 0px;
  padding: 0px;
}
body {
  margin: auto 5%;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* ############# boxes CSS ################## */
.myBoxes{
  background-color: white;
  border-radius: 12px;
}
.myBoxes-2{
  background-color: rgb(232, 232, 232);
  border-radius: 12px;
}
.myBoxes-black{
  background-color: rgb(0, 0, 0);
  border-radius: 12px;
  /* color: white; */
}

/* sticky css */

.sticky-div {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  background-color: white;
  border-radius: 10px;
  z-index: 1000;
}


/* Spinner style  */
.spinner-parent {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: azure;
}

.spinner {
  width: 80px;
  height: 80px;
  position: relative;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  
  -webkit-animation: bounce 2.0s infinite ease-in-out;
  animation: bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}


/* Spinner in a div */
.spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 600px; /* Match the height of the iframe */
}

.spinner-border {
    width: 3rem;
    height: 3rem;
    border: 0.3rem solid rgba(0, 0, 0, 0.1);
    border-top: 0.3rem solid #007bff;
    border-radius: 50%;
    animation: spinner-border 0.75s linear infinite;
}

@keyframes spinner-border {
    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes bounce {
  0%, 100% {
    -webkit-transform: scale(0.0);
    transform: scale(0.0);
  } 
  50% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

@keyframes bounce {
  0%, 100% {
    -webkit-transform: scale(0.0);
    transform: scale(0.0);
  } 
  50% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}


/* Certification page css */
.pdf-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
}

.pdf-preview {
    width: 100%;
    max-width: 800px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
}

.error {
    color: red;
    font-weight: bold;
}


/* Stickey css changes here */
.sticky-top-offset {
  position: -webkit-sticky;
  position: sticky;
  top: 56px; /* Adjust this value based on the height of your header */
  z-index: 1020;
  background-color: rgba(255, 255, 255, 0); /* Ensure background color to avoid transparency issues */
  /* box-shadow: 0 0px 6px -1px rgb(192, 190, 190) */

}



/* Max height for pills on observation reading page */
.nav-container {
  max-height: 80px; /* Assuming each row is 38px in height */
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
}

.nav-container .nav-item {
  flex: 1 0 5%; /* Adjust the width to fit items per row */
}

.nav-container .nav-link {
  display: block;
  white-space: nowrap;
}

/* Active tabs on header css  */
.nav-link {
  color: #000;
  padding: 10px;
  border-radius: 50px;
}

/* Style for the active navigation links (dark pill) */
.nav-link.active {
  background-color: #343a40;
  color: #fff !important;
}


/* Go to top css  */

/* GoToTopButton.css */
.go-to-top-button {
  position: fixed;
  bottom: 40px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #00de3f;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s;
  z-index: 1000;
}

.go-to-top-button:hover {
  background-color: #0056b3;
}


.transition-width {
  transition: width 0.5s ease, margin-left 0.5s ease;
  position: relative; /* Ensure the element is positioned relative to allow margin adjustments */
}

.col-sm-9 {
  width: 75%; /* Adjust according to your column size */
  margin-left: 25%; /* Adjust according to the width change to keep it aligned to the left */
}

.col-sm-12 {
  width: 100%; /* Adjust according to your column size */
  margin-left: 0; /* Reset margin-left when full width */
}

.scrollable-div {
  max-height: 650px; /* Set the maximum height for the scrollable area */
  overflow-y: auto; /* Enable vertical scrolling */
}
.scrollable-div-sidebar {
  max-height: 90%; /* Set the maximum height for the scrollable area */
  overflow-y: auto; /* Enable vertical scrolling */
}
/* Custom styles for the sidebar container */
.p-sidebar-mask.p-component-overlay {
  pointer-events: none;
}

/* Div focus class */
.div_focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  border-radius: 10px;
  box-shadow: 0 0 0 .20rem rgba(13, 110, 253, 0.25);
  padding-top: 1%;
  padding-bottom: 1%;
  transition: all 0.3s ease-in-out; /* Added transition for smooth animation */
}

.div_focus:hover {
  /* background-color: #f8f9fa; */
  box-shadow: 0 0 0 .25rem rgba(13, 110, 253, 0.35); /* Change box shadow on hover */
}
.invalid_input{
  border: 1px solid red;
  color: red;
}
.collapse-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
}

.collapse-content.open {
    max-height: 2600px; /* Arbitrary large value to accommodate content */
    transition: max-height 0.5s ease-in;
}


/* SkeletonLoader.css */
.skeleton-loader {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  background: #fff;
  width: 100%;
  /* max-width: 600px; */
  margin: 0 auto;
}

.skeleton-header,
.skeleton-footer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.submitting-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's above other content */
}

.submitting-message {
  text-align: center;
  color: white;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.requiredClass input, .requiredClass select {
  border: 1px solid red;
}